import React, { useState } from 'react'
import { Link } from 'react-router-dom';
import {
    Collapse,
    Navbar,
    NavbarToggler,
    NavbarBrand,
    Nav,
    NavItem,
    NavLink,
    UncontrolledDropdown,
    DropdownToggle,
    DropdownMenu,
    DropdownItem,
    NavbarText,
    Button,
} from 'reactstrap';
import { FaFacebook } from "react-icons/fa";
import { FaTwitter } from "react-icons/fa6";
import { FaInstagram } from "react-icons/fa";
import Pdf from '../img/doc/whitepaper.pdf';
import guide from '../img/doc/guide.pdf';
import businessCase from '../img/doc/business-use-case.pdf';


function NavbarMenu() {
    const [isOpen, setIsOpen] = useState(false);
    const toggle = () => setIsOpen(!isOpen);

    return (
        <div>
            <Navbar className='bg-nav' light dark container expand='md'>
                <NavbarToggler onClick={toggle} />
                <Collapse isOpen={isOpen} navbar>
                    <Nav className="me-auto" navbar>
                        <NavItem>
                            <Link to='/'>Home</Link>

                        </NavItem>
                        {/* <NavItem>
                                    <NavLink href="/components/">Components</NavLink>
                                </NavItem> */}

                        <NavItem >
                            <Link to='/about'>About</Link>
                        </NavItem>
                        {/* <NavItem>
                            <Link to='/products'>Our Solutions</Link>
                        </NavItem> */}
                        <UncontrolledDropdown nav inNavbar>
                            <DropdownToggle nav caret className='pt-16' >
                                Our Solutions
                            </DropdownToggle>
                            <DropdownMenu right >
                            <DropdownItem href='/products'> Our Solutions</DropdownItem>
                                <DropdownItem
                                href={Pdf}>SMKG White Paper</DropdownItem>
                                <DropdownItem 
                                href={guide}>SMKG PaaS Journey</DropdownItem>
                                <DropdownItem href='/token-coin-listing'>Token/Coin Listing</DropdownItem>
                                <DropdownItem href={businessCase} >Business Use Case</DropdownItem>
                            </DropdownMenu>
                        </UncontrolledDropdown>
                        <NavItem>
                            <Link to='/excutiveteam'>Executive team</Link>
                        </NavItem>
                        <NavItem>
                            <Link to='/news'>News & Investors</Link>
                        </NavItem>
                        <NavItem>
                            <Link to='/interview'>Interview</Link>
                        </NavItem>
                        {/* <NavItem>
                            <Link to='/token-coin-listing'>Token/coin Listing</Link>
                        </NavItem> */}
                        <NavItem>
                            <Link to='/contact'>Contact</Link>
                        </NavItem>
                        {/* <NavItem>
                                    <NavLink href="/"></NavLink>
                                </NavItem>
                                <NavItem>
                                    <NavLink href="/"></NavLink>
                                </NavItem>
                                <NavItem>
                                    <NavLink href="/"></NavLink>
                                </NavItem>
                                <NavItem>
                                    <NavLink href="/"></NavLink>
                                </NavItem>
                                <NavItem>
                                    <NavLink href="/"></NavLink>
                                </NavItem> */}
                        {/* <UncontrolledDropdown nav inNavbar>
                                    <DropdownToggle nav caret>
                                        Options
                                    </DropdownToggle>
                                    <DropdownMenu right>
                                        <DropdownItem>Option 1</DropdownItem>
                                        <DropdownItem>Option 2</DropdownItem>
                                        <DropdownItem divider />
                                        <DropdownItem>Reset</DropdownItem>
                                    </DropdownMenu>
                                </UncontrolledDropdown> */}
                    </Nav>
                    {/* <NavbarText>
                       
                    </NavbarText>
                    <NavbarText>
                        
                    </NavbarText>
                    <NavbarText>
                        
                    </NavbarText> */}
                </Collapse>
            </Navbar>
        </div>
    )
}

export default NavbarMenu